<template>
  <component :is="pageData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="pageData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات صفحة
      </h4>
      <div class="alert-body">
        لم يتم العثور على صفحة مع معرف صفحة هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'pages-list'}"
        >
          قائمة صفحة
        </b-link>
        صفحة الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="pageData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('page_edit') }}</span>
        </template>
        <page-edit-tab-information
          :page-data="pageData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import pageStore from '../pageStore';
import PageEditTabInformation from './PageEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    PageEditTabInformation,
  },
  setup() {
    const pageData = ref(null);

    const PAGE_APP_STORE_MODULE_NAME = 'app-page';

    // Register module
    if (!store.hasModule(PAGE_APP_STORE_MODULE_NAME)) store.registerModule(PAGE_APP_STORE_MODULE_NAME, pageStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PAGE_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-page/fetchPage', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: page } = response.data;
        pageData.value = page;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          pageData.value = undefined;
        }
      });

    return {
      pageData,
    };
  },
};
</script>

<style>

</style>
