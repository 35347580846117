import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/page', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/page/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePage(ctx, { pageData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/page/${id}`, pageData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/page/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPage(ctx, pageData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/page', pageData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLayouts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/layout')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReports(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/report')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
