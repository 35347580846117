<template>
  <div>
    <!-- Form: Page Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: layout_id -->
          <validation-provider
            #default="validationContext"
            vid="layout_id"
            name="layout_id"
            rules="required"
          >
            <b-form-group
              label="تنسيق الصفحة"
              label-for="layout_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.layout_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="layouts"
                :reduce="val => val.value"
                :clearable="false"
                input-id="layout_id"
                @input="layoutChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: report_id -->
          <validation-provider
            #default="validationContext"
            vid="report_id"
            name="report_id"
            rules="required"
          >
            <b-form-group
              label="التقرير"
              label-for="report_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.report_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="reports"
                :reduce="val => val.value"
                :clearable="false"
                input-id="report_id"
                @input="reportChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: name -->
          <validation-provider
            #default="validationContext"
            vid="name"
            name="name"
            rules="required"
          >
            <b-form-group
              label="اسم"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="pageData.name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: description -->
          <validation-provider
            #default="validationContext"
            vid="description"
            name="description"
            rules="required"
          >
            <b-form-group
              label="وصف"
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="description"
                v-model="pageData.description"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- orientation -->
          <validation-provider
            #default="validationContext"
            name="orientation"
            rules="required"
          >
            <b-form-group
              label="إتجاه الصفحة"
              label-for="orientation"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.orientation"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="orientations"
                :reduce="val => val.value"
                :clearable="false"
                input-id="orientation"
                @input="orientationChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end orientation -->
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- size -->
          <validation-provider
            #default="validationContext"
            name="size"
            rules="required"
          >
            <b-form-group
              label="مقاس الصفحة"
              label-for="size"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.size"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="sizes"
                :reduce="val => val.value"
                :clearable="false"
                input-id="size"
                @input="sizeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end size -->
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updatePage"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import pageStore from '../pageStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    updatePage() {
      this.$store
        .dispatch('app-page/updatePage', { pageData: this.pageData, id: this.pageData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/page').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    layoutChanged(layout) {
      this.pageData.layout_id = layout;
    },
    reportChanged(report) {
      this.pageData.report_id = report;
    },

  },

  setup(props) {
    const PAGE_STORE_MODULE_NAME = 'page';

    // Register page
    if (!store.hasModule(PAGE_STORE_MODULE_NAME)) store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_STORE_MODULE_NAME)) store.unregisterModule(PAGE_STORE_MODULE_NAME);
    });
    const layouts = ref([]);
    const res = store.dispatch('page/fetchLayouts')
      .then((response) => {
        const { data } = response;
        layouts.value = data.map((c) => ({ label: c.description, value: c.id, ...c }));
      });
    const reports = ref([]);
    const ress = store.dispatch('page/fetchReports')
      .then((response) => {
        const { data } = response;
        reports.value = data.map((c) => ({ label: c.description, value: c.id, ...c }));
      });

    const oldPageData = JSON.parse(JSON.stringify(props.pageData));
    const resetPageData = () => {
      pageData.value = JSON.parse(JSON.stringify(oldPageData));
    };

    const sizes = [
      {
        label: 'A4',
        value: 'A4',
      },
      {
        label: 'A5',
        value: 'A5',
      },
    ];

    const orientations = [
      {
        label: 'عمودي',
        value: 'Portrait',
      },
      {
        label: 'أفقي',
        value: 'Landscape',
      },
    ];

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPageData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      sizes,
      orientations,
      layouts,
      reports,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
